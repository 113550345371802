<!-- 创建等级 -->
<template>
    <div class="orderdetailsbox">
        <div class="gradesnav">
            <el-tabs v-model="geadesindex">
                <el-tab-pane label="基础信息" name="1"></el-tab-pane>
                <el-tab-pane label="升级策略" name="2"></el-tab-pane>
            </el-tabs>
        </div>
        <el-form :model="levelInfo" label-width="120px" :rules="rules" ref="levelForm">
            <div class="memberdetail">
                <!-- 基础信息 -->
                <div class="memberitem" v-show="geadesindex == 1">
                    <div class="geadesup">
                        <div class="mtitle">
                            <h4>等级信息</h4>
                        </div>
                        <div class="geadesupcon">
                            <ul>
                                <li>
                                    <el-form-item class="clearfloat" label="等级权重" prop="level">
                                        <!-- <span class="geadeslabel">等级权重</span> -->
                                        <div class="geadesright">
                                            <template>
                                                <el-select v-model="levelInfo.level" clearable placeholder="请选择">
                                                    <el-option v-for="item in levelArr" :key="item.key" :value="item.key"
                                                        :label="item.value"></el-option>
                                                </el-select>
                                            </template>
                                        </div>
                                    </el-form-item>
                                </li>
                                <li>
                                    <el-form-item label="等级名称" class="clearfloat" prop="name">
                                        <!-- <span class="geadeslabel"><i>*</i>等级名称</span> -->
                                        <div class="geadesright">
                                            <el-input type="text" v-model.trim="levelInfo.name" maxlength="10"
                                                show-word-limit class="inputbox" placeholder="请填写等级名称">
                                            </el-input>
                                        </div>
                                    </el-form-item>
                                </li>
                                <li>
                                    <el-form-item label="购买价格" class="clearfloat" prop="price">
                                        <!-- <span class="geadeslabel"><i>*</i>等级名称</span> -->
                                        <div class="geadesright">
                                            <el-input type="number" v-model.trim="levelInfo.price" class="inputbox"
                                                placeholder="请填写购买价格">
                                            </el-input>
                                        </div>
                                    </el-form-item>
                                </li>
                                <li>
                                    <el-form-item class="clearfloat" label="会员图片" prop="image">
                                        <!-- <span><i>*</i> 用户头像</span> -->
                                        <div class="addressright">
                                            <div class="addresstx" @click="handleImageClick">
                                                <i class="el-icon-plus" v-show="!levelInfo.image"></i>
                                                <img class="addresstximage" :src="levelInfo.image"
                                                    v-show="!!levelInfo.image" />
                                            </div>
                                        </div>
                                    </el-form-item>
                                </li>
                                <li>
                                    <el-form-item class="clearfloat" label="等级描述" prop="detail">
                                        <!-- <span class="geadeslabel"><i>*</i>等级描述</span> -->
                                        <div class="geadesright">
                                            <el-input type="textarea" v-model.trim="levelInfo.detail" maxlength="90"
                                                show-word-limit class="textareabox" placeholder="请填写等级描述">
                                            </el-input>
                                        </div>
                                    </el-form-item>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div class="geadesup">
                        <div class="mtitle">
                            <h4>等级权益</h4>
                        </div>
                        <div class="geadesupcon">
                            <ul>
                                <li class="clearfloat">
                                    <el-form-item label="权益设置" prop="status">
                                        <!-- <span class="geadeslabel">权益设置</span> -->
                                        <div class="geadesright geadesrighted">
                                            <template>
                                                <el-radio-group v-model="levelInfo.status">
                                                    <el-radio v-for="(item, index) in userLevelStatus" :key="index"
                                                        :label="Number(item.value)">{{ item.label }}</el-radio>
                                                </el-radio-group>
                                            </template>
                                        </div>
                                    </el-form-item>
                                </li>
                                <li class="clearfloat" v-if="levelInfo.status == 1">
                                    <el-form-item label="等级权益" prop="discount">
                                        <!-- <span class="geadeslabel"><i>*</i>等级权益</span> -->
                                        <div class="geadesright">
                                            <div class="geadesrightinput">
                                                <el-input type="number" v-model="levelInfo.discount"></el-input>
                                                <span>折</span>
                                            </div>
                                        </div>
                                    </el-form-item>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- 升级策略 -->
                <div class="memberitem" v-show="geadesindex == 2">
                    <div class="upgrade">
                        <div class="mtitle">
                            <h4>升级策略</h4>
                        </div>
                        <div class="geadesupcon">
                            <ul>
                                <li class="clearfloat">
                                    <el-form-item label="升级策略" prop="strategy_status">
                                        <!-- <span class="geadeslabel">升级策略</span> -->
                                        <div class="geadesright geadesrighted">
                                            <template>
                                                <el-radio-group v-model="levelInfo.strategy_status">
                                                    <el-radio v-for="(item, index) in userLevelStrategyStatus" :key="index"
                                                        :label="Number(item.value)">{{ item.label }}</el-radio>
                                                </el-radio-group>
                                            </template>
                                            <div class="upgradelist clearfloat">
                                                <p v-for="(item, index) in userLevelStrategy"
                                                    :class="activelist.indexOf(Number(item.upgradeinedx)) != -1 ? 'active' : ''"
                                                    v-text="item.upgradetext" :key="index"
                                                    @click="upgradetab(Number(item.upgradeinedx))">
                                                </p>
                                            </div>
                                        </div>
                                    </el-form-item>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="upgrade" v-if="activelist && activelist.length">
                        <div class="mtitle">
                            <h4>升级条件限制</h4>
                        </div>
                        <div class="geadesupcon upgradecon">
                            <ul>
                                <li class="clearfloat" v-if="activelist.indexOf(1) != -1">
                                    <el-form-item label="单笔消费金额" prop="strategy[1]">
                                        <!-- <span class="geadeslabel"><i>*</i> 单笔消费金额</span> -->
                                        <div class="geadesright clearfloat">
                                            <div class="upgradeinput">
                                                <!-- <el-input-number :precision="2" :controls="false"
                                                    v-model="levelInfo.strategy['1']"></el-input-number>
                                                <span>元</span> -->
                                                <el-input type="number" v-model="levelInfo.strategy['1']">
                                                    <template slot="append">积分</template>
                                                </el-input>
                                            </div>
                                            <a href="javascript:void(0);" class="upgradelect" @click="updelect(1)">删除</a>
                                        </div>
                                    </el-form-item>
                                </li>
                                <li class="clearfloat" v-if="activelist.indexOf(2) != -1">
                                    <el-form-item label="累计消费金额" prop="strategy[2]">
                                        <!-- <span class="geadeslabel"><i>*</i> 累计消费金额</span> -->
                                        <div class="geadesright clearfloat">
                                            <div class="upgradeinput">
                                                <el-input type="number" v-model="levelInfo.strategy['2']">
                                                    <template slot="append">元</template>
                                                </el-input>
                                            </div>
                                            <a href="javascript:void(0);" class="upgradelect" @click="updelect(2)">删除</a>
                                        </div>
                                    </el-form-item>
                                </li>
                                <li class="clearfloat" v-if="activelist.indexOf(3) != -1">
                                    <el-form-item label="单笔充值金额" prop="strategy[3]">
                                        <!-- <span class="geadeslabel"><i>*</i> 单笔充值金额</span> -->
                                        <div class="geadesright clearfloat">
                                            <div class="upgradeinput">
                                                <el-input type="number" v-model="levelInfo.strategy['3']"></el-input>
                                                <span>元</span>
                                            </div>
                                            <a href="javascript:void(0);" class="upgradelect" @click="updelect(3)">删除</a>
                                        </div>
                                    </el-form-item>
                                </li>
                                <li class="clearfloat" v-if="activelist.indexOf(4) != -1">
                                    <el-form-item label="累计充值金额" prop="strategy[4]">
                                        <!-- <span class="geadeslabel"><i>*</i> 累计充值金额</span> -->
                                        <div class="geadesright clearfloat">
                                            <div class="upgradeinput">
                                                <input type="number" v-model="levelInfo.strategy['4']">
                                                <span>元</span>
                                            </div>
                                            <a href="javascript:void(0);" class="upgradelect" @click="updelect(4)">删除</a>
                                        </div>
                                    </el-form-item>
                                </li>
                                <li class="clearfloat" v-if="activelist.indexOf(5) != -1">
                                    <span class="geadeslabel"><i>*</i> 指定商品</span>
                                    <div class="geadesright">
                                        <div class="geadesrightcon clearfloat">
                                            <div class="geadeslast">
                                                <button @click="handleIncrementGoods">添加商品</button>
                                            </div>
                                            <a href="javascript:void(0);" class="upgradelect" @click="updelect(5)">删除</a>
                                        </div>
                                        <div class="addgeades" v-show="selectGoodsList.length > 0">
                                            <div class="batchtable">
                                                <div class="batchtablecon">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th class="tableleft">商品</th>
                                                                <th>价格</th>
                                                                <th>操作</th>
                                                            </tr>
                                                        </thead>
                                                        <colgroup>
                                                            <col style="width:56%">
                                                            <col style="width:22%">
                                                            <col style="width:22%">
                                                        </colgroup>
                                                        <tbody>
                                                            <tr v-for="item in selectGoodsList" :key="item.id">
                                                                <td class="tableleft">
                                                                    <div class="shoplister clearfloat">
                                                                        <div class="shopimg"><img :src="item.cover_pic"
                                                                                alt="">
                                                                        </div>
                                                                        <div class="shoptext">
                                                                            <h6>{{ item.name }}</h6>
                                                                            <!-- <div class="shoptextlast">
                                                                                <p>价格：<span>积分199.00</span></p>
                                                                                        </div> -->
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{{ item.price }}</td>
                                                                <td>
                                                                    <div class="setoperate">
                                                                        <a href="javascript:void(0);"
                                                                            @click="handleGoodsDelete(item.id)">删除</a>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- 降级策略 v-if="geadesindex == 3" -->
                <div class="memberitem" v-if="false">
                    <div class="demote">
                        <div class="mtitle">
                            <h4>降级策略</h4>
                        </div>
                        <div class="geadesupcon">
                            <ul>
                                <li class="clearfloat">
                                    <span class="geadeslabel">降级策略</span>
                                    <div class="geadesright geadesrighted">
                                        <template>
                                            <el-radio v-model="demoteradio" label="1" @change="open">开启</el-radio>
                                            <el-radio v-model="demoteradio" label="2">关闭</el-radio>
                                        </template>
                                        <p>开启时，未满足条件自动降级</p>
                                    </div>
                                </li>
                                <template v-if='demoteradio == 1'>
                                    <li class="clearfloat" v-if='radiodemote != 0'>
                                        <span class="geadeslabel"></span>
                                        <div class="geadesright geadesrighted">
                                            <p v-if='radiodemote == 1'>新策略： 所有该等级会员从 <strong>{{ demotetime }}</strong>
                                                开始执行降级策略
                                            </p>
                                            <p v-if='radiodemote == 2'>新策略： 新升级成该等级的会员 <strong>30</strong> 天后执行降级策略</p>
                                        </div>
                                    </li>
                                    <li class="clearfloat">
                                        <span class="geadeslabel">等级保护期</span>
                                        <div class="geadesright geadesrighted">
                                            <div class="geadesrightinput">
                                                <input type="number" value="30">
                                                <span>天</span>
                                            </div>
                                            <p>在保护期内，会员不自动降级，保护期过后未满足条件时自动降级。</p>
                                        </div>
                                    </li>
                                    <li class="clearfloat">
                                        <span class="geadeslabel">降级条件</span>
                                        <div class="geadesright clearfloat">
                                            <div class="demotelist clearfloat">
                                                <div class="demotelefts">
                                                    <el-radio v-model="stringradio" label="1"></el-radio>
                                                </div>
                                                <div class="demoterights">
                                                    近<div class="geadesrightinput">
                                                        <input type="number" value="30">
                                                        <span>天</span>
                                                    </div>
                                                    满足以下任意条件
                                                </div>
                                            </div>
                                            <div class="demotelist clearfloat">
                                                <div class="demotelefts">
                                                    <el-radio v-model="stringradio" label="2"></el-radio>
                                                </div>
                                                <div class="demoterights">无条件自动降级</div>
                                            </div>
                                        </div>
                                    </li>
                                    <template v-if="stringradio == 1">
                                        <li class="clearfloat">
                                            <span class="geadeslabel"></span>
                                            <div class="setgeades clearfloat">
                                                <span class="ordercheck"><el-checkbox></el-checkbox></span>
                                                <div class="setgeadestext">
                                                    单笔消费金额未满
                                                    <div class="geadesrightinput">
                                                        <input type="number">
                                                        <span>元</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="clearfloat">
                                            <span class="geadeslabel"></span>
                                            <div class="setgeades clearfloat">
                                                <span class="ordercheck"><el-checkbox></el-checkbox></span>
                                                <div class="setgeadestext">
                                                    累计消费金额未满
                                                    <div class="geadesrightinput">
                                                        <input type="number">
                                                        <span>元</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="clearfloat">
                                            <span class="geadeslabel"></span>
                                            <div class="setgeades clearfloat">
                                                <span class="ordercheck"><el-checkbox></el-checkbox></span>
                                                <div class="setgeadestext">
                                                    单笔充值金额未满
                                                    <div class="geadesrightinput">
                                                        <input type="number">
                                                        <span>元</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="clearfloat">
                                            <span class="geadeslabel"></span>
                                            <div class="setgeades clearfloat">
                                                <span class="ordercheck"><el-checkbox></el-checkbox></span>
                                                <div class="setgeadestext">
                                                    累计充值金额未满
                                                    <div class="geadesrightinput">
                                                        <input type="number">
                                                        <span>元</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="clearfloat">
                                            <span class="geadeslabel"></span>
                                            <div class="setgeades clearfloat">
                                                <span class="ordercheck"><el-checkbox></el-checkbox></span>
                                                <div class="setgeadestext">
                                                    <div class="setgeadestextcon">
                                                        未购买指定商品 <button @click="shoptype = true">添加商品</button>
                                                    </div>
                                                    <!-- 模拟数据 -->
                                                    <div class="addgeades" style="display: none;">
                                                        <div class="batchtable">
                                                            <div class="batchtablecon">
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="tableleft">商品</th>
                                                                            <th>最少购买数量</th>
                                                                            <th>操作</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <colgroup>
                                                                        <col style="width:56%">
                                                                        <col style="width:22%">
                                                                        <col style="width:22%">
                                                                    </colgroup>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td class="tableleft">
                                                                                <div class="shoplister clearfloat">
                                                                                    <div class="shopimg"><img src="" alt="">
                                                                                    </div>
                                                                                    <div class="shoptext">
                                                                                        <h6>杯子男家用马克杯瓷杯陶瓷女创意个性北欧办公室喝水杯早餐杯
                                                                                        </h6>
                                                                                        <div class="shoptextlast">
                                                                                            <p>价格：<span>199.00积分</span></p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div class="smallnums">
                                                                                    <input type="number"
                                                                                        placeholder="最少购买数量">
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div class="setoperate">
                                                                                    <a href="javascript:void(0);">删除</a>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </template>
                                    <li class="clearfloat">
                                        <span class="geadeslabel">会员降级为</span>
                                        <div class="geadesright geadesrighted">
                                            <template>
                                                <el-radio v-model="demotedradio" label="1">降一级</el-radio>
                                                <el-radio v-model="demotedradio" label="2">降级到特定等级</el-radio>
                                            </template>
                                            <div v-if="demotedradio == 2">
                                                <template>
                                                    <el-select v-model="demotevalue" clearable placeholder="请选择">
                                                        <el-option value="全部">全部</el-option>
                                                        <el-option value="一级">一级</el-option>
                                                        <el-option value="二级">二级</el-option>
                                                        <el-option value="三级">三级</el-option>
                                                        <el-option value="四级">四级</el-option>
                                                        <el-option value="五级">五级</el-option>
                                                        <el-option value="六级">六级</el-option>
                                                        <el-option value="七级">七级</el-option>
                                                        <el-option value="八级">八级</el-option>
                                                        <el-option value="九级">九级</el-option>
                                                    </el-select>
                                                </template>
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </el-form>

        <diy-footer-button>
            <el-button @click="handleCancel">取消</el-button>
            <el-button type="primary" @click="handle()" v-if="geadesindex > 1">上一步</el-button>
            <template v-if="geadesindex == 1">
                <el-button type="primary" @click="handle(geadesindex)">{{ geadesindex == 1 ? "下一步" : $route.query &&
                    $route.query.id ? "更新"
                    :
                    "保存"
                }}</el-button>
            </template>
            <template v-else>
                <el-button type="primary" @click="handle(geadesindex)"
                    v-if="hasPerm(['user.level.update', 'user.level.store'])">{{
                        geadesindex
                        == 1 ? "下一步" : $route.query && $route.query.id ? "更新" : "保存"
                    }}</el-button>
            </template>
        </diy-footer-button>
        <!-- 商品选择器 -->
        <shopselector ref="shopList" @change="goodshoplist($event)"></shopselector>
        <!-- 图片 -->
        <pictureSelect ref="imglist" :multipled="is_multiple" @change="imgbrand($event)"></pictureSelect>

    </div>
</template>

<script>
import { Message } from 'element-ui';
import { mapState } from 'vuex';
import common from '../../common/common';
import { hasPermission } from '../../utils/permission';

export default {
    watch: {
        // 监听路由query参数
        "$route.query": {
            handler(newVal, oldVal) {
                const { id } = newVal;
                if (newVal && id) {
                    //根据id查询会员信息
                    this.$get(`${this.$apis.memberLevel}/${id}`).then(res => {
                        if (res.code == 200) {
                            //赋值回显
                            this.$nextTick(() => {
                                // 格式化数据
                                const result = this.transformFormatToFn(res.data.strategy)
                                this.$set(this, "levelInfo", { ...res.data, strategy: result });
                                this.$set(this, "selectGoodsList", res.data.goods_list);
                                // 将strategy数据放入到activelist中
                                Object.keys(this.levelInfo.strategy).forEach(item => {
                                    this.activelist.push(Number(item));
                                    this.$forceUpdate();
                                })
                            })
                        } else {
                            Message.error({ message: res.message });
                            this.handleCancel();
                        }
                    })
                }
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            levelInfo: {//基础信息表单项
                level: "",//等级
                name: "",//等级名称
                discount: "",//折扣
                price: "",//会员价格
                detail: "", //描述
                image: "",//会员头像
                status: 0,//开启权益 0:关闭 1:开启
                strategy_status: 1,//1:任意策略 2:全部策略
                strategy: {
                    '5': []
                },
            },
            levelArr: [
                { key: 0, value: "全部" },
                { key: 1, value: "一级" },
                { key: 2, value: "二级" },
                { key: 3, value: "三级" },
                { key: 4, value: "四级" },
                { key: 5, value: "五级" },
                { key: 6, value: "六级" },
                { key: 7, value: "七级" },
                { key: 8, value: "八级" },
                { key: 9, value: "九级" },
            ],
            geadesindex: '1',//顶部导航栏索引
            is_multiple: 0,//头像单选多选0:单选，1:多选
            activelist: [1],
            upgradelist: [{
                upgradetext: '单笔消费金额',
                upgradeinedx: 1
            },
            {
                upgradetext: '累计消费金额',
                upgradeinedx: 2
            },
            {
                upgradetext: '单笔充值金额',
                upgradeinedx: 3
            },
            {
                upgradetext: '累计充值金额',
                upgradeinedx: 4
            },
            {
                upgradetext: '购买指定商品',
                upgradeinedx: 5
            }
            ],
            rules: {
                "level": [{ required: true, validator: this.levelValid, trigger: ['change', 'blur'] }],
                "name": [{ required: true, validator: this.nameValid, trigger: ['change', 'blur'] }],
                "discount": [{ required: true, validator: this.discountValid, trigger: 'blur' },],
                "detail": [{ required: true, message: "请输入等级描述!", trigger: 'blur' }],
                "status": [{ required: true, message: "请选择是否开启权限!", trigger: ['change', 'blur'] }],
                "strategy_status": [{ required: true, message: "请选择是否开启升级策略!", trigger: ['change', 'blur'] }],
                "strategy[1]": [{ required: true, message: "请输入单笔消费金额!", trigger: 'blur' }],
                "strategy[2]": [{ required: true, message: "请输入累计消费金额!", trigger: 'blur' }],
                "strategy[3]": [{ required: true, message: "请输入单笔充值金额!", trigger: 'blur' }],
                "strategy[4]": [{ required: true, message: "请输入累计充值金额!", trigger: 'blur' }],
            },//表单验证规则
            resources: {
                "user.level_status": {},
                "user.level_strategy": {},
                "user.level_strategy_status": {}
            },
            // 选择的商品列表
            selectGoodsList: []
        }
    },
    created() {
        this.getResources();
    },
    mounted() { },
    methods: {
        discountValid(rules, value, callback) {
            if (value == "") {
                callback(new Error("请输入折扣价!"));
            }
            if (value < 0 || value > 10) {
                callback(new Error("折扣价只能在[0 - 10]区间之间!"));
            }
            if (value.indexOf(".") != -1 && value.slice(value.indexOf(".") + 1).length >= 2) {
                callback(new Error("只允许保留1位小数!"));
            }
            callback();
        },
        // levelValid
        levelValid(rules, value, callback) {
            if (value == "" && !this.$route.query.id) {
                callback(new Error("请选择等级权重!"));
            }
            this.$get(this.$apis.levelIsExistApi, { level: value }).then(res => {
                if (res.code != 200 && !this.$route.query.id) {
                    callback(new Error(res.message));
                }
                callback();
            })
        },
        // nameValid
        nameValid(rules, value, callback) {
            if (value == "" && !this.$route.query.id) {
                callback(new Error("请输入等级名称!"));
            }
            if (value.length > 10) {
                callback(new Error("最大只能填写10个字符!"));
            }
            this.$get(this.$apis.levelNameIsExistApi, { name: value }).then(res => {
                if (res.code != 200 && !this.$route.query.id) {
                    callback(new Error(res.message));
                }
                callback();
            })
        },
        // 获取部分全局资源，以防与商品选择器资源冲突
        getResources() {
            this.$get(this.$apis.resource, { keys: Array.of("user.level_status", "user.level_strategy_status", "user.level_strategy") }).then(res => {
                if (res.code != 200) {
                    return Message.error(res.message);
                }
                this.$set(this, 'resources', {
                    "user.level_status": res.data['user.level_status'],
                    "user.level_strategy": res.data['user.level_strategy'],
                    "user.level_strategy_status": res.data['user.level_strategy_status']
                })
            })
        },
        // 导航tab切换
        geadestab(val) {
            this.geadesindex = val;
        },
        // 选择头像事件
        imgbrand(val) {
            this.levelInfo.image = val[0].file_url;
            this.$forceUpdate();
        },
        //上传头像事件
        handleImageClick() {
            this.$refs.imglist.photoVisible = true;
        },
        // 升级策略tab切换
        upgradetab(index) {
            if (this.activelist.indexOf(index) == -1) {
                this.activelist.push(index);
            }
        },
        // 取消
        handleCancel() {
            this.$refs['levelForm']['resetFields']();
            this.$router.replace("/channel/member/member_level");
        },
        // 下一步/保存事件/更新/编辑
        handle(type) {
            let timer = null;
            if (type == 1) { //下一步
                this.geadesindex = 2;
            } else if (type == undefined) {
                this.geadesindex = 1; //上一步
            } else {
                if (this.activelist.length <= 0) {
                    return Message.error("请至少选择一条升级策略!");
                }
                // //console.log();
                this.$refs['levelForm']['validate']().then(res => {
                    // 深拷贝数据，使得后续操作不更改原数据
                    const newLevelInfo = JSON.parse(JSON.stringify(this.levelInfo));
                    // 调用数据格式转换函数
                    const result = this.transformFormatFn(newLevelInfo.strategy);
                    newLevelInfo.strategy = result;
                    // //console.log(result,newLevelInfo);
                    if (this.activelist.includes(5) && this.levelInfo.strategy['5'].length == 0) {
                        return Message.warning("请选择商品!");
                    }
                    //提交
                    this[this.$route.query && this.$route.query.id ? "$put" : "$post"](`${this.$apis.memberLevel}${this.$route.query && this.$route.query.id ? '/' + this.$route.query.id : ""}`, newLevelInfo).then(res => {
                        if (res.code == 200) {
                            Message.success({
                                message: `${this.$route.query && this.$route.query.id ? '更新' : '添加'}会员等级成功!`, offset: 200, onClose: () => {
                                    // 清空表单,返回主页面
                                    this.handleCancel();
                                }
                            });
                        } else {
                            // Message.error({ message: `${this.$route.query && this.$route.query.id ? '更新' : '添加'}会员等级失败!-[${res.message}]`, offset: 200 });
                            Message.error({ message: res.message, offset: 200 });
                        }
                    })
                }, err => {
                    const result = Object.values(err)
                    result.flat(1).forEach((item, index) => {
                        timer = setTimeout(() => {
                            Message.error({
                                message: item.message,
                            });
                        }, 0)
                    })
                })
                // this.$refs['levelForm']['validate'](valid => {
                //     if (valid) {
                //         // 深拷贝数据，使得后续操作不更改原数据
                //         const newLevelInfo = JSON.parse(JSON.stringify(this.levelInfo));
                //         // 调用数据格式转换函数
                //         const result = this.transformFormatFn(newLevelInfo.strategy);
                //         newLevelInfo.strategy = result;
                //         // //console.log(result,newLevelInfo);
                //         if(this.activelist.includes(5) && this.levelInfo.strategy['5'].length == 0){
                //             return Message.warning("请选择商品!");
                //         }
                //         //提交
                //         this[this.$route.query && this.$route.query.id ? "$put" : "$post"](`${this.$apis.memberLevel}${this.$route.query && this.$route.query.id ? '/' + this.$route.query.id : ""}`, newLevelInfo).then(res => {
                //             if (res.code == 200) {
                //                 Message.success({
                //                     message: `${this.$route.query && this.$route.query.id ? '更新' : '添加'}会员等级成功!`, offset: 200, onClose: () => {
                //                         // 清空表单,返回主页面
                //                         this.handleCancel();
                //                     }
                //                 });
                //             } else {
                //                 // Message.error({ message: `${this.$route.query && this.$route.query.id ? '更新' : '添加'}会员等级失败!-[${res.message}]`, offset: 200 });
                //                 Message.error({ message: res.message, offset: 200 });
                //             }
                //         })
                //     }else{
                //         //console.log(this.$refs['levelForm']);
                //     }
                // })
            }
        },
        /**
         * 转换levelInfo.strategy数据格式方法：strategy{"1",[string->array],"2":[string->array],...}
         * @param {*} value：需要转换的数据对象
         * @return 转换后的数据格式
         */
        transformFormatFn(value) {
            const result = Object.entries(value).map(item => {
                item[0] = item[0].toString();
                item[1] = item[1] == "" ? [] : Array.isArray(item[1]) ? item[1] : Array.of(item[1])
                return item;
            })
            return Object.fromEntries(result);
        },
        /**
         * 转换后台数据strategy数据格式方法：strategy:JSON->Object->{"1":[array->string]...}
         * @param {*} value：需要转换的数据对象
         * @return 转换后的数据格式
         */
        transformFormatToFn(value) {
            // 将JSON转为Object
            const newVal = JSON.parse(value);
            // 转换Object value格式
            const entriesData = Object.entries(newVal).map(item => {
                // 判断item[1]的类型是否是数组并暂且排除key=5的数据(20230609解除&& item[0] != 5)
                if (Array.isArray(item[1]) && item[0] != 5) {
                    // 转换
                    item[1] = item[1].join();
                    return item;
                } else {
                    return item;
                }
            })
            return Object.fromEntries(entriesData);
        },
        // 删除
        updelect(index) { //此处的index并非是索引，而是前面传递的 upgradeinedx值，不能够当做索引删除，否则有异常

            // 判断是否为最后一个
            if (this.activelist.length == 1) {
                return Message.warning("升级策略最少选择一个!");
            } else {
                if (index == 5) {
                    this.$set(this, 'selectGoodsList', []);
                    this.$set(this.levelInfo.strategy, '5', []);
                }
                // 在activelist中查找值
                const idx = this.activelist.findIndex(item => item == index);
                // 通过idx删除
                this.activelist.splice(idx, 1);
            }
        },
        // 商品选择器方法
        goodshoplist(event) {
            const ids = event.map(item => item.id);
            this.$set(this.levelInfo.strategy, '5', this.levelInfo.strategy['5'].concat(ids));
            this.$set(this, 'selectGoodsList', this.selectGoodsList.concat(event))
        },
        // 添加升级策略
        handleIncrementGoods() {
            this.$refs.shopList.shoptype = true;
        },
        // 删除商品
        handleGoodsDelete(id) {
            const index = this.selectGoodsList.findIndex(item => item.id == id);
            this.selectGoodsList.splice(index, 1);
            // 删除strategy中的id
            const idx = this.levelInfo.strategy['5'].findIndex(item => item == id);
            this.levelInfo.strategy['5'].splice(idx, 1)
        }
    },
    computed: {
        // status计算属性
        userLevelStatus() {
            return common.mapSource2List(this.resources['user.level_status'] || {});
        },
        // strategyStatus计算属性
        userLevelStrategyStatus() {
            return common.mapSource2List(this.resources['user.level_strategy_status'] || {});
        },
        // strategy计算属性
        userLevelStrategy() {
            return common.mapSource2List(this.resources['user.level_strategy'] || {}, 'upgradetext', 'upgradeinedx');
        },
    }

}
</script>

<style scoped lang="less">
@import url("css/member.css");

.gradesnav {
    padding: 0;

    & /deep/.el-tabs__header {
        background-color: #fff;
        border-radius: 4px;

        & .el-tabs__nav-wrap::after {
            background-color: transparent !important;
        }

        & .el-tabs__item {
            // &:hover {
            //   color: #000d9a;
            // }

            // &.is-active {
            //   color: var(--fontColor) !important;
            // }

            padding: 0;
            width: 96px;
            font-size: 14px;
            font-family: PingFangSC-Medium,
                PingFang SC;
            font-weight: 500;
            text-align: center;
        }

        // & .el-tabs__active-bar {
        //   // width: 96px !important;
        //   height: 2px;
        //   background: #000d9a;
        // }

        & .el-tabs__nav-scroll {
            height: 48px;
            padding-left: 20px;
            border-radius: 4px;

            & .el-tabs__nav {
                height: 100%;

                & .el-tabs__active-bar {
                    transform: translateX(96px + 78px);
                }

                & .el-tabs__item {
                    // width: 180px;
                    width: auto;
                    height: 100%;
                    // font-size: 18px;
                    // font-weight: 700;
                    line-height: 48px;
                    padding-right: 78px;
                    text-align: left;
                    margin: 0;
                    font-size: 14px;
                    font-weight: 400;
                    // color: #333333;
                }
            }
        }
    }

    & /deep/.el-tabs__content {
        width: 100%;
        background-color: #ffffff;
        border-radius: 4px;
    }
}

.addressright {
    width: 80px !important;
}

/deep/.el-input-number {
    width: 100%;
}

.upgradeinput span {
    top: 2px !important;
}

.addresstximage {
    display: block;
    width: 80px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    border: 1px dashed #ededed;
    box-sizing: border-box;
    font-size: 40px;
    color: #dad9d9;
    font-weight: 400;
    cursor: pointer;
    object-fit: cover;
}

// .upgradecon .clearfloat {
//     height: 40px;
// }
</style>